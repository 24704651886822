import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  canShowToast: boolean = true;

  constructor(
    public toastController: ToastController
  ) { }

  public async success(mensagem) {
    if (this.canShowToast) {
      this.canShowToast = false;
      const toast = await this.toastController.create({
        header: 'SUCESSO',
        message: mensagem,
        position: 'top',
        duration: 3000,
        cssClass: 'toast-success',
        buttons: [
          {
            text: 'Fechar',
            role: 'cancel',
            handler: () => {
            }
          }
        ]
      });
      toast.present();
      toast.onDidDismiss().then(() => {
        this.canShowToast = true;
      })
    } else {
      this.error(mensagem);
    }
  }

  public async warning(mensagem) {
    if (this.canShowToast) {
      this.canShowToast = false;
      const toast = await this.toastController.create({
        header: 'ATENÇÃO',
        message: mensagem,
        position: 'top',
        duration: 3000,
        cssClass: 'toast-warning',
        buttons: [
          {
            text: 'Fechar',
            role: 'cancel',
            handler: () => {
            }
          }
        ]
      });
      toast.present();
      toast.onDidDismiss().then(() => {
        this.canShowToast = true;
      })
    } else {
      this.error(mensagem);
    }
  }

  public async info(mensagem, tempo = 3000) {
    if (this.canShowToast) {
      this.canShowToast = false;
      const toast = await this.toastController.create({
        header: 'INFORMAÇÃO',
        message: mensagem,
        position: 'top',
        duration: tempo,
        cssClass: 'toast-warning',
        buttons: [
          {
            text: 'Fechar',
            role: 'cancel',
            handler: () => {
            }
          }
        ]
      });
      toast.present();
      toast.onDidDismiss().then(() => {
        this.canShowToast = true;
      })
    } else {
      this.error(mensagem);
    }
  }

  public async error(mensagem, duration = 3000) {
    if (this.canShowToast) {
      this.canShowToast = false;
      const toast = await this.toastController.create({
        header: 'ERRO',
        message: mensagem,
        position: 'top',
        duration: duration,
        cssClass: 'toast-error',
        buttons: [
          {
            text: 'Fechar',
            role: 'cancel',
            handler: () => {
            }
          }
        ]
      });
      toast.present();
      toast.onDidDismiss().then(() => {
        this.canShowToast = true;
      })
    } else {
      this.error(mensagem);
    }
  }

}
