import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { SessionInterceptor } from './interceptors/session.interceptor';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { TouchID } from '@ionic-native/touch-id/ngx';
import { Toast } from '@ionic-native/toast/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
// import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
import { DatePipe } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import {registerLocaleData} from '@angular/common';
import { Ndef, NFC } from '@awesome-cordova-plugins/nfc/ngx';
import { IonicSelectableModule } from 'ionic-selectable';
import { InputSearchPipe } from './pipes/input-search.pipe';
import { SearchComponent } from './helpers/search/search/search.component';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';


registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    InputSearchPipe,
    // SearchComponent
  ], 
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule,
    IonicSelectableModule,
    FormsModule,
  ],
  providers: [
    FingerprintAIO,
    TouchID,
    Toast,
    Keyboard,
    AppVersion,
    Diagnostic,
    Geolocation,
    OpenNativeSettings,
    Camera,
    MediaCapture,
    Vibration,
    Network,
    InAppBrowser,
    Device,
    // FileTransfer,
    ReactiveFormsModule,
    FormBuilder,
    HTTP,
    BackgroundMode,
    AndroidPermissions,
    LocalNotifications,
    DatePipe,
    NFC,
    Ndef,
    NativeGeocoder,
    ScreenOrientation,
    BarcodeScanner,
    {
      provide: LOCALE_ID,
      useValue: "pt"
    },
    {
        provide:  DEFAULT_CURRENCY_CODE,
        useValue: 'BRL'
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
