import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class RegistroDePontoService {
  serverUrl: string = localStorage.getItem('SERVER_URL');
  options: { headers: HttpHeaders; };

  constructor(
    private http: HttpClient,
    private http2: HTTP,
    private appVersion: AppVersion,
    private platform: Platform
  ) {

    this.platform.ready().then(() => {
      this.serverUrl = localStorage.getItem('SERVER_URL');
    });
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    this.options = {
      headers: headers
    }
  }

  sugestaoTipoMarcacao() {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    console.log(this.serverUrl)
    return this.http.get(this.serverUrl + '?c=controle.CAppPoCartaoPonto&m=sugestaoTipoMarcacao', this.options);
  }

  registrarBatidaPonto(nrLatitude, nrLongitude, csTipoMarcacao, imgSelfie, senha) {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    let body = 'nrLatitude=' + nrLatitude + '&nrLongitude=' + nrLongitude + '&csTipoMarcacao=' + csTipoMarcacao + '&csOrigemMarcacao=2&senha=' + senha + '&img=' + imgSelfie;
    return this.http.post(this.serverUrl + '?c=controle.CAppPoCartaoPonto&m=registrarBatidasPonto', body, this.options);
  }

  registrarBatidaPontoV2(nrLatitude: string, nrLongitude: string, csTipoMarcacao: string, imgSelfie, senha: string, session_id, idFuncionario, versaoApp, conexao) {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    this.http2.setHeader("*", "debug", "true");


    console.log('nrLatitude: ', nrLatitude)
    console.log('nrLongitude: ', nrLongitude)
    console.log('csTipoMarcacao: ', csTipoMarcacao)
    console.log('senha: ', senha)
    console.log('session: ', session_id)
    console.log('idFuncionario: ', idFuncionario)
    console.log('versaoApp: ', versaoApp)
    console.log('conexao: ', conexao)

    let endpoint = this.serverUrl + "?c=controle.CAppPoCartaoPonto&m=registrarBatidasPonto&nrLatitude=" + nrLatitude + "&nrLongitude=" + nrLongitude + "&csTipoMarcacao=" + csTipoMarcacao + "&senha=" + senha + "&session=" + session_id + "&idFuncionario=" + idFuncionario + "&versaoApp=" + versaoApp + "&conexao=" + conexao;
    console.log("ENDPOINT: ", endpoint)
    let req;
    if (imgSelfie?.length) {
      req = this.http2.uploadFile(this.serverUrl + '?c=controle.CAppPoCartaoPonto&m=registrarBatidasPonto', {
        nrLatitude: nrLatitude,
        nrLongitude: nrLongitude,
        csTipoMarcacao: csTipoMarcacao,
        senha: senha,
        nmArquivo: "foto-batida-1.jpg",
        session: session_id,
        idFuncionario: idFuncionario,
        versaoApp: versaoApp,
        conexao: conexao,
      }, {}, imgSelfie?.length ? encodeURI(imgSelfie) : '', 'arquivoImg');
    } else {
      req = this.http2.get(this.serverUrl + '?c=controle.CAppPoCartaoPonto&m=registrarBatidasPonto', {
        nrLatitude: nrLatitude,
        nrLongitude: nrLongitude,
        csTipoMarcacao: csTipoMarcacao,
        senha: senha,
        nmArquivo: "foto-batida-1.jpg",
        session: session_id,
        idFuncionario: idFuncionario,
        versaoApp: versaoApp,
        conexao: conexao,
      }, {});
    }
    return req;
  }

  registrarBatidaPontoOffline(idFuncionario, nrLatitude, nrLongitude, csTipoMarcacao, imgSelfie, hrBatida, dtBatida) {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    let body = 'idFuncionario=' + idFuncionario + '&nrLatitude=' + nrLatitude + '&nrLongitude=' + nrLongitude + '&csTipoMarcacao=' + csTipoMarcacao + '&csOrigemMarcacao=2&img=' + imgSelfie + '&hrBatida=' + hrBatida + '&dtBatida=' + dtBatida;
    return this.http.post(this.serverUrl + '?c=controle.CAppPoCartaoPonto&m=sincronizarBatidas', body, this.options);
  }

  getPontosBatidos() {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    return this.http.get(this.serverUrl + '?c=controle.CAppPoCartaoPonto&m=recuperarPontoEletronicoHoje', this.options);
  }

  getEditarTipo(idHorasTrabalhadasPontoEle, tipoPontoBatido) {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    let body = 'idHorasTrabalhadasPontoEle=' + idHorasTrabalhadasPontoEle + '&csTipoMarcacao=' + tipoPontoBatido;
    return this.http.post(this.serverUrl + '?c=controle.CAppPoCartaoPonto&m=alterarTipoBatidaPonto', body, this.options);
  }

  getExcluirBatidaPonto(idHorasTrabalhadasPontoEle) {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    let body = 'idHorasTrabalhadasPontoEle=' + idHorasTrabalhadasPontoEle;
    return this.http.post(this.serverUrl + '?c=controle.CAppPoCartaoPonto&m=excluirBatidaPonto', body, this.options);
  }

  registrarJustificativaBatidaPonto(nrLatitude, nrLongiude, csTipoMarcacao, imgSelfie, dtBatida = null, dtPrevista = null, qtDiferencaMinutos = null, idLocalPrevisto = null, nrDistanciaLocal = null, boForaToleranciaHorario = null, boForaToleranciaLocal = null, idTipoOcorrencia = null, teJustificativa = null, dtBatidaSaidaGps, csTipoCritica, idTipoCritica, senha) {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    let body = 'nrLatitude=' + nrLatitude + '&nrLongitude=' + nrLongiude + '&csTipoMarcacao=' + csTipoMarcacao + '&csOrigemMarcacao=2&img=' + imgSelfie + '&dtBatida=' + dtBatida + '&dtPrevista=' + dtPrevista + '&qtDiferencaMinutos=' + qtDiferencaMinutos + '&idLocalPrevisto=' + idLocalPrevisto + '&nrDistanciaLocal=' + nrDistanciaLocal + '&boForaToleranciaHorario=' + boForaToleranciaHorario + '&boForaToleranciaLocal=' + boForaToleranciaLocal + '&idTipoOcorrencia=' + idTipoOcorrencia + '&teJustificativa=' + teJustificativa + '&dtBatidaSaidaGps=' + dtBatidaSaidaGps + '&csTipoCritica=' + csTipoCritica + '&idTipoCritica=' + idTipoCritica + '&senha=' + senha;
    return this.http.post(this.serverUrl + '?c=controle.CAppPoCartaoPonto&m=registrarBatidasPonto', body, this.options);
  }

  registrarNaoConcordandoJustificativaBatidaPonto(nrLatitude, nrLongiude, csTipoMarcacao, imgSelfie, dtBatida, dtPrevista, qtDiferencaMinutos, idLocalPrevisto = null, nrDistanciaLocal = null, boForaToleranciaHorario = null, boForaToleranciaLocal = null, idTipoOcorrencia = null, teJustificativa = null, dtBatidaSaidaGps, csTipoCritica, idTipoCritica, senha) {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    let body = 'nrLatitude=' + nrLatitude + '&nrLongitude=' + nrLongiude + '&csTipoMarcacao=' + csTipoMarcacao + '&csOrigemMarcacao=2&img=' + imgSelfie + '&dtBatida=' + dtBatida + '&dtPrevista=' + dtPrevista + '&qtDiferencaMinutos=' + qtDiferencaMinutos + '&idLocalPrevisto=' + idLocalPrevisto + '&nrDistanciaLocal=' + nrDistanciaLocal + '&boForaToleranciaHorario=' + boForaToleranciaHorario + '&boForaToleranciaLocal=' + boForaToleranciaLocal + '&idTipoOcorrencia=' + idTipoOcorrencia + '&teJustificativa=' + teJustificativa + '&dtBatidaSaidaGps=' + dtBatidaSaidaGps + '&csTipoCritica=' + csTipoCritica + '&idTipoCritica=' + idTipoCritica + '&boForaToleranciaAdicionalSaida=1&senha=' + senha;
    return this.http.post(this.serverUrl + '?c=controle.CAppPoCartaoPonto&m=registrarBatidasPonto', body, this.options);
  }

  registrarConcordandoBatidaPonto(nrLatitude, nrLongiude, csTipoMarcacao, imgSelfie, boForaToleranciaAdicionalSaida = 0, senha, dtBatidaAdicionalSaida) {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    let body = 'nrLatitude=' + nrLatitude + '&nrLongitude=' + nrLongiude + '&csTipoMarcacao=' + csTipoMarcacao + '&csOrigemMarcacao=2&img=' + imgSelfie + '&boForaToleranciaAdicionalSaida=0&senha=' + senha + '&dtBatidaAdicionalSaida=' + dtBatidaAdicionalSaida;
    return this.http.post(this.serverUrl + '?c=controle.CAppPoCartaoPonto&m=registrarBatidasPonto', body, this.options);
  }
}
