import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RegistrarLogService {
  serverUrl: string = localStorage.getItem('SERVER_URL');
  options: { headers: any; };

  constructor(
    public platform: Platform,
    private device: Device,
    private http: HttpClient
  ) {
    this.platform.ready().then(() => {
      this.serverUrl = localStorage.getItem('SERVER_URL');
    });
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    this.options = {
      headers: headers
    }
  }

  public func = Object.freeze({
    AceitarTermoUso: 1,
    AssinarContraCheque: 2,
    AssinarPonto: 3,
    AssinaturaAnualPage: 4,
    BaixarCartaoPonto: 5,
    BaixarContraCheque: 6,
    CanalDenunciaPage: 7,
    ConfiguracoesPage: 8,
    ConsultarContraCheque: 9,
    ConsultarEscala: 10,
    ConsultarPonto: 11,
    FeriasPage: 12,
    Home: 13,
    InformeRendimentosPage: 14,
    Login: 15,
    MediaKm: 16,
    PrimeiroLogin: 17,
    Reclamacao: 18,
    RegistrarOcorrencia: 19,
    RegistrarPonto: 20,
    ReparoListaPage: 21,
    Sair: 22,
    TabsOcorrencia: 23,
    Termos: 24,
    TermoUsoMenuPage: 25,
    TrocaEscalasPage: 26
  });

  registrar(funcionalidade) {
    this.serverUrl = localStorage.getItem('SERVER_URL');

    let latitude = localStorage.getItem('latitude');
    let longitude = localStorage.getItem('longitude');

    var fabricante = this.device.manufacturer;
    var modelo = this.device.model;
    var versaoSo = this.device.version;

    if (this.platform.is('android')) {
      var so = 'android';
    } else {
      var so = 'ios';
    }

    var ip = localStorage.getItem('nrIp');

    const date = new Date();
    // this.storage.set('dtAceitacaoTermoRh', date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear())

    var data_acesso = date.getFullYear() + "-";
    data_acesso += this.padLeft(date.getMonth().toString(), '0', 2) + "-";
    data_acesso += this.padLeft(date.getDate().toString(), '0', 2);

    var hora_acesso = this.padLeft(date.getHours().toString(), '0', 2) + ':';
    hora_acesso += this.padLeft(date.getMinutes().toString(), '0', 2) + ':';
    hora_acesso += this.padLeft(date.getSeconds().toString(), '0', 2);

    var idFuncionario = idFuncionario;

    let body = 'dataAcesso=' + data_acesso;
    body += '&horaAcesso=' + hora_acesso;
    body += '&ip=' + ip;
    body += '&fabricante=' + fabricante;
    body += '&modelo=' + modelo;
    body += '&versaoSo=' + versaoSo;
    body += '&so=' + so;
    body += '&funcionalidade=' + funcionalidade;
    body += '&latitude=' + latitude;
    body += '&longitude=' + longitude;

    // this.registrarAnalytics(body);

    // return this.http.post('http://localhost:8000/views', body, this.options);
    return this.http.post(this.serverUrl + '?c=controle.CAppCrFuncionario&m=registrarLog', body, this.options);
    
  }
  
  registrarAnalytics(body) {
    console.log(body)
    let teste = this.http.post('http://localhost:8000/views', body, this.options);
    console.log(teste)
    return teste;
  }

  padLeft(text:string, padChar:string, size:number): string {
		return (String(padChar).repeat(size) + text).substr( (size * -1), size) ;
  }

}
