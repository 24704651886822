import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
// import { AutoLoginGuard } from './guards/auto-login.guard';

const session_id = localStorage.getItem('session_id');

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login2/login2.module').then( m => m.Login2PageModule),
    // canLoad: [AutoLoginGuard] // Check if we should show the introduction or forward to inside
  },
  {
    path: '',
    // redirectTo: 'login',
    redirectTo: session_id?.length ? 'home' : 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'configuracoes',
    loadChildren: () => import('./pages/configuracoes/configuracoes/configuracoes.module').then( m => m.ConfiguracoesPageModule)
  },
  {
    path: 'canal-de-denuncias',
    loadChildren: () => import('./pages/canal-de-denuncias/canal-de-denuncias-routing.module').then( m => m.CanalDeDenunciasPageRoutingModule)
  },
  {
    path: 'troca-senha',
    loadChildren: () => import('./pages/configuracoes/troca-senha/troca-senha.module').then( m => m.TrocaSenhaPageModule)
  },
  {
    path: 'escala/listar',
    loadChildren: () => import('./pages/escala/escala-listar/escala-listar.module').then( m => m.EscalaListarPageModule)
  },
  {
    path: 'escala/detalhes',
    loadChildren: () => import('./pages/escala/escala-detalhes/escala-detalhes.module').then( m => m.EscalaDetalhesPageModule)
  },
  {
    path: 'ferias/listar',
    loadChildren: () => import('./pages/ferias/ferias-listar/ferias-listar.module').then( m => m.FeriasListarPageModule)
  },
  {
    path: 'ferias/detalhes',
    loadChildren: () => import('./pages/ferias/ferias-detalhes/ferias-detalhes.module').then( m => m.FeriasDetalhesPageModule)
  },
  {
    path: 'canal-de-denuncias',
    loadChildren: () => import('./pages/canal-de-denuncias/canal-de-denuncias.module').then( m => m.CanalDeDenunciasPageModule)
  },
  {
    path: 'informe-de-rendimentos/listar',
    loadChildren: () => import('./pages/informe-de-rendimentos/informe-de-rendimentos-listar/informe-de-rendimentos-listar.module').then( m => m.InformeDeRendimentosListarPageModule)
  },
  {
    path: 'informe-de-rendimentos/detalhes',
    loadChildren: () => import('./pages/informe-de-rendimentos/informe-de-rendimentos-detalhes/informe-de-rendimentos-detalhes.module').then( m => m.InformeDeRendimentosDetalhesPageModule)
  },
  {
    path: 'cartao-de-ponto/listar',
    loadChildren: () => import('./pages/cartao-de-ponto/cartao-de-ponto-listar/cartao-de-ponto-listar.module').then( m => m.CartaoDePontoListarPageModule)
  },
  {
    path: 'cartao-de-ponto/detalhes',
    loadChildren: () => import('./pages/cartao-de-ponto/cartao-de-ponto-detalhes/cartao-de-ponto-detalhes.module').then( m => m.CartaoDePontoDetalhesPageModule)
  },
  {
    path: 'comunicacao/listar',
    loadChildren: () => import('./pages/comunicacao/notificacoes/notificacoes.module').then( m => m.NotificacoesPageModule)
  },
  {
    path: 'escala-listar',
    loadChildren: () => import('./pages/escala/escala-listar/escala-listar.module').then( m => m.EscalaListarPageModule)
  },
  {
    path: 'escala-detalhes',
    loadChildren: () => import('./pages/escala/escala-detalhes/escala-detalhes.module').then( m => m.EscalaDetalhesPageModule)
  },
  {
    path: 'registrar-ocorrencia',
    loadChildren: () => import('./pages/ocorrencia/registrar-ocorrencia/registrar-ocorrencia.module').then( m => m.RegistrarOcorrenciaPageModule)
  },
  {
    path: 'registro-de-ponto/registro',
    loadChildren: () => import('./pages/registro-de-ponto/registro-online/registro-online.module').then( m => m.RegistroOnlinePageModule)
  },
  {
    path: 'registro-de-ponto/registro-offline',
    loadChildren: () => import('./pages/registro-de-ponto/registro-offline/registro-offline.module').then( m => m.RegistroOfflinePageModule)
  },
  {
    path: 'registro-de-ponto-listar',
    loadChildren: () => import('./pages/registro-de-ponto/registro-de-ponto-listar/registro-de-ponto-listar.module').then( m => m.RegistroDePontoListarPageModule)
  },
  {
    path: 'notificacao-detalhes',
    loadChildren: () => import('./pages/comunicacao/notificacao-detalhes/notificacao-detalhes.module').then( m => m.NotificacaoDetalhesPageModule)
  },
  {
    path: 'escala-locais-detalhes',
    loadChildren: () => import('./pages/escala/escala-locais-detalhes/escala-locais-detalhes.module').then( m => m.EscalaLocaisDetalhesPageModule)
  },
  {
    path: 'termos-de-uso',
    loadChildren: () => import('./pages/termos-de-uso/termos-de-uso/termos-de-uso.module').then( m => m.TermosDeUsoPageModule)
  },
  {
    path: 'contra-cheque/listar',
    loadChildren: () => import('./pages/contra-cheque/contra-cheque-listar/contra-cheque-listar.module').then( m => m.ContraChequeListarPageModule)
  },
  {
    path: 'contra-cheque/detalhes',
    loadChildren: () => import('./pages/contra-cheque/contra-cheque-detalhes/contra-cheque-detalhes.module').then( m => m.ContraChequeDetalhesPageModule)
  },
  {
    path: 'termos-de-uso-modal',
    loadChildren: () => import('./pages/termos-de-uso/termos-de-uso-modal/termos-de-uso-modal.module').then( m => m.TermosDeUsoModalPageModule)
  },
  {
    path: 'contra-cheque-termo-assinatura',
    loadChildren: () => import('./pages/contra-cheque/contra-cheque-termo-assinatura/contra-cheque-termo-assinatura.module').then( m => m.ContraChequeTermoAssinaturaPageModule)
  },
  {
    path: 'ver-anexo',
    loadChildren: () => import('./pages/ocorrencia/ver-anexo/ver-anexo.module').then( m => m.VerAnexoPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'solicitacao-de-reparo/listar',
    loadChildren: () => import('./pages/solicitacao-de-reparo/solicitacao-de-reparo-listar/solicitacao-de-reparo-listar.module').then( m => m.SolicitacaoDeReparoListarPageModule)
  },
  {
    path: 'solicitacao-de-reparo/detalhes',
    loadChildren: () => import('./pages/solicitacao-de-reparo/solicitacao-de-reparo-detalhes/solicitacao-de-reparo-detalhes.module').then( m => m.SolicitacaoDeReparoDetalhesPageModule)
  },
  {
    path: 'solicitacao-de-reparo/inserir',
    loadChildren: () => import('./pages/solicitacao-de-reparo/solicitacao-de-reparo-inserir/solicitacao-de-reparo-inserir.module').then( m => m.SolicitacaoDeReparoInserirPageModule)
  },
  {
    path: 'cartao-de-ponto-termo-assinatura',
    loadChildren: () => import('./pages/cartao-de-ponto/cartao-de-ponto-termo-assinatura/cartao-de-ponto-termo-assinatura.module').then( m => m.CartaoDePontoTermoAssinaturaPageModule)
  },
  {
    path: 'assinatura-anual/listar',
    loadChildren: () => import('./pages/assinatura-anual/assinatura-anual-listar/assinatura-anual-listar.module').then( m => m.AssinaturaAnualListarPageModule)
  },
  {
    path: 'assinatura-anual/detalhes',
    loadChildren: () => import('./pages/assinatura-anual/assinatura-anual-detalhes/assinatura-anual-detalhes.module').then( m => m.AssinaturaAnualDetalhesPageModule)
  },
  {
    path: 'media-de-km/listar',
    loadChildren: () => import('./pages/media-de-km/media-de-km-listar/media-de-km-listar.module').then( m => m.MediaDeKmListarPageModule)
  },
  {
    path: 'media-de-km/detalhes',
    loadChildren: () => import('./pages/media-de-km/media-de-km-detalhes/media-de-km-detalhes.module').then( m => m.MediaDeKmDetalhesPageModule)
  },
  {
    path: 'meus-dados/detalhes',
    loadChildren: () => import('./pages/meus-dados/meus-dados-detalhes/meus-dados-detalhes.module').then( m => m.MeusDadosDetalhesPageModule)
  },
  {
    path: 'registro-de-ponto-modal',
    loadChildren: () => import('./pages/registro-de-ponto/registro-de-ponto-modal/registro-de-ponto-modal.module').then( m => m.RegistroDePontoModalPageModule)
  },
  {
    path: 'bloqueio-tela',
    loadChildren: () => import('./pages/shared/bloqueio-tela/bloqueio-tela.module').then( m => m.BloqueioTelaPageModule)
  },
  {
    path: 'registro-de-acidentes/listar',
    loadChildren: () => import('./pages/registro-de-acidentes/registro-de-acidentes-listar/registro-de-acidentes-listar.module').then( m => m.RegistroDeAcidentesListarPageModule)
  },
  {
    path: 'registrar-acidente',
    loadChildren: () => import('./pages/registro-de-acidentes/registrar-acidente/registrar-acidente.module').then( m => m.RegistrarAcidentePageModule)
  },
  {
    path: 'registrar-acidente-detalhar-acidente',
    loadChildren: () => import('./pages/registro-de-acidentes/registrar-acidente-detalhar-acidente/registrar-acidente-detalhar-acidente.module').then( m => m.RegistrarAcidenteDetalharAcidentePageModule)
  },
  {
    path: 'registrar-acidente-detalhar-veiculos',
    loadChildren: () => import('./pages/registro-de-acidentes/registrar-acidente-detalhar-veiculos/registrar-acidente-detalhar-veiculos.module').then( m => m.RegistrarAcidenteDetalharVeiculosPageModule)
  },
  { 
    path: 'registrar-acidente-detalhar-vitimas',
    loadChildren: () => import('./pages/registro-de-acidentes/registrar-acidente-detalhar-vitimas/registrar-acidente-detalhar-vitimas.module').then( m => m.RegistrarAcidenteDetalharVitimasPageModule)
  },
  {
    path: 'registrar-acidente-detalhar-testemunhas',
    loadChildren: () => import('./pages/registro-de-acidentes/registrar-acidente-detalhar-testemunhas/registrar-acidente-detalhar-testemunhas.module').then( m => m.RegistrarAcidenteDetalharTestemunhasPageModule)
  },
  {
    path: 'registrar-acidente-comentarios',
    loadChildren: () => import('./pages/registro-de-acidentes/registrar-acidente-comentarios/registrar-acidente-comentarios.module').then( m => m.RegistrarAcidenteComentariosPageModule)
  },
  {
    path: 'acertos/listar',
    loadChildren: () => import('./pages/acertos/acertos-listar/acertos-listar.module').then( m => m.AcertosListarPageModule)
  },
  {
    path: 'acertos/detalhes',
    loadChildren: () => import('./pages/acertos/acertos-detalhes/acertos-detalhes.module').then( m => m.AcertosDetalhesPageModule)
  },
  {
    path: 'conta-corrente/listar',
    loadChildren: () => import('./pages/conta-corrente/conta-corrente-listar/conta-corrente-listar.module').then( m => m.ContaCorrenteListarPageModule)
  },
  {
    path: 'documentos-do-veiculo',
    loadChildren: () => import('./pages/documentos-do-veiculo/documentos-do-veiculo.module').then( m => m.DocumentosDoVeiculoPageModule)
  },
  {
    path: 'conta-corrente-detalhes',
    loadChildren: () => import('./pages/conta-corrente/conta-corrente-detalhes/conta-corrente-detalhes.module').then( m => m.ContaCorrenteDetalhesPageModule)
  },
  {
    path: 'viagens-realizadas',
    loadChildren: () => import('./pages/viagens-realizadas/viagens-realizadas/viagens-realizadas.module').then( m => m.ViagensRealizadasPageModule)
  },
  {
    path: 'despachante/registro-de-ponto',
    loadChildren: () => import('./pages/despachante/despachante-registro-de-ponto/despachante-registro-de-ponto.module').then( m => m.DespachanteRegistroDePontoPageModule)
  },
  {
    path: 'despachante/troca-de-escala',
    loadChildren: () => import('./pages/despachante/despachante-troca-de-escala/despachante-troca-de-escala.module').then( m => m.DespachanteTrocaDeEscalaPageModule)
  },
  {
    path: 'search-modal',
    loadChildren: () => import('./helpers/search/search/search-modal/search-modal.module').then( m => m.SearchModalPageModule)
  },
  {
    path: 'cartao-de-ponto-detalhes-info',
    loadChildren: () => import('./pages/cartao-de-ponto/cartao-de-ponto-detalhes-info/cartao-de-ponto-detalhes-info.module').then( m => m.CartaoDePontoDetalhesInfoPageModule)
  },
  {
    path: 'notificacao-obrigatoria-detalhes',
    loadChildren: () => import('./pages/comunicacao/notificacao-obrigatoria-detalhes/notificacao-obrigatoria-detalhes/notificacao-obrigatoria-detalhes.module').then( m => m.NotificacaoObrigatoriaDetalhesPageModule)
  },
  {
    path: 'ferias-termo-assinatura',
    loadChildren: () => import('./pages/ferias/ferias-termo-assinatura/ferias-termo-assinatura.module').then( m => m.FeriasTermoAssinaturaPageModule)
  }
]; 

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
