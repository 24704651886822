import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inputSearch'
})
export class InputSearchPipe implements PipeTransform {

  transform(value: any, input: any, attribute: any): any {
    if (input) {
       let arr = value.filter(val => val[attribute].toLowerCase().indexOf(input.toLowerCase()) >= 0);
       if(arr?.length > 0) {
         return arr;
       } else {
         return null
       }
    } else {
      return value;
    }
  }
}
