import { ToastService } from './../services/components/toast/toast.service';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MenuController, ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { LoginService } from '../services/login/login.service';
import { AppVersion } from '@ionic-native/app-version/ngx';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  manterConectado: any;
  boLoginOffLineHabilitado: any = localStorage.getItem('boLoginOffLineHabilitado');
  version: any;

  constructor(
    private router: Router,
    private toast: ToastService,
    public menuCtrl: MenuController,
    private loginService: LoginService,
    private appVersion: AppVersion
  ) {
    this.appVersion.getVersionNumber().then((dados) => {
      this.version = dados;
      localStorage.setItem('versaoApp', dados);
    }).catch(error => {
      console.error(error);
      this.version = '2.1.26';
      localStorage.setItem('versaoApp', this.version);
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const session_id = localStorage.getItem('session_id');
    const idFuncionario = localStorage.getItem('idFuncionario');
    const conexao = localStorage.getItem('CONEXAO');
    this.manterConectado = localStorage.getItem('manterConectado');

    // console.log(request)
    let url = request.url;
    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')
    });
    if (!url.includes('localhost') && url.includes('https://base.transoft.com.br/api-video-app') == false) {
      if (conexao != null && conexao != 'null' && conexao != undefined && (!url.includes('conexao') || url.includes('conexao=undefined'))) {
        request = request.clone({ url: request.url + "&conexao=" + conexao });
      }
      if (!url.includes('versaoApp')) {

        let versaoApp = localStorage.getItem('versaoApp');
        request = request.clone({ url: request.url + "&versaoApp=" + versaoApp });
      }

      if (!url.includes('validarEmpresa') || !url.includes('=validar&')) {
        if (session_id != null && session_id != 'null' && session_id != undefined && !url.includes('session')) {
          request = request.clone({ url: request.url + "&session=" + session_id });
        }
        if (idFuncionario != null && idFuncionario != 'null' && idFuncionario != undefined && !url.includes('idFuncionario')) {
          request = request.clone({ url: request.url + "&idFuncionario=" + idFuncionario });
        }
      }
      
      const salt = (new Date()).getTime();
      request = request.clone({ url: request.url + '&' + salt });
    }

    return next.handle(request).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // console.log('event--->>>', event);
        const response = (event.body as any);


        if (response.sessaoInativa == "1") {
          console.warn(request)
          console.warn(response)
          this.toast.error('Sua sessão expirou, faça o login novamente.')
          this.menuCtrl.close().then(() => {
            this.router.navigateByUrl('/login', { replaceUrl: true });
            localStorage.removeItem('session_id');
          });
        }

        // this.manterConectado == 'true';
        // if (response.sessaoInativa == "1" && this.manterConectado != 'true') {
        //   this.toast.error('Sua sessão expirou, faça o login novamente.')
        //   this.menuCtrl.close().then(() => {
        //     this.router.navigateByUrl('/login', { replaceUrl: true });
        //     localStorage.removeItem('session_id');
        //   });
        // } else if (response.sessaoInativa == "1" && this.manterConectado == 'true') {
        //   let cpf = localStorage.getItem('cpf');
        //   let senha = localStorage.getItem('senha');
        //   let idEmpresa = localStorage.getItem('idEmpresa');
        //   // let idEmpresa = localStorage.getItem('idEmpresa');
        //   this.loginService.login(cpf, senha, idEmpresa).subscribe(dados => {
        //     const response = (dados as any);
        //     if (!url.includes('contagemOcoPorFuncionario')) {
        //       this.toast.warning('Sua sessão foi renovada, tente novamente!')
        //     }
        //     // if (response.aconteceu != true) {
        //     return next.handle(request).pipe(map((event2: HttpEvent<any>) => {
        //       // alert(event2)
        //       return event2;
        //     }, error => {
        //       console.error(error)
        //     }));
        //     // }
        //   });
        // }
      }
      return event;
    }),
      catchError((error: HttpErrorResponse) => {
        // if (error.status === 401) {
        // if (error.error.success === false) {
        //   if(error.status === 0) {
        //     this.toast.error(error)
        //   } 
        if (!url.includes('validar')) {
          // this.menuCtrl.close().then(() => {
          //   this.router.navigateByUrl('/login', { replaceUrl: true });
          //   localStorage.removeItem('session_id');
          // });
          // this.toast.error('ERRO ' + error.status + ': Ocorreu um erro nesta solicitação, tente novamente...')
        }
        console.log('Erro de conexao: ' + JSON.stringify(error))
        if(!navigator.onLine && !url.includes('CAppCrOcorrenciaFuncionario')) {
          this.router.navigateByUrl('/login', { replaceUrl: true });
          // localStorage.removeItem('session_id');
        }
        // }
        return throwError(error);
      }));
  }
}