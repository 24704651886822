import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  serverUrl: string;
  conexao: string;
  session: string;
  idFuncionario: string;
  idEmpresas: any;
  options: { headers: HttpHeaders; };

  constructor(
    private http: HttpClient,
    private platform: Platform
  ) {
    this.platform.ready().then(() => {
      this.serverUrl = localStorage.getItem('SERVER_URL');
      this.conexao = localStorage.getItem('CONEXAO');
      this.idEmpresas = localStorage.getItem('ID_EMPRESAS');
    });
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    this.options = {
      headers: headers
    }
  }

  validarEmpresa(cpf) {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    this.idEmpresas = localStorage.getItem('ID_EMPRESAS');
    return this.http.get(this.serverUrl + '?c=controle.CAppCaInfraLogin&m=validarEmpresa&nrCpf=' + cpf + '&id_empresas=' + this.idEmpresas);
  }

  validarCpf(cpf, idEmpresa) {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    return this.http.get(this.serverUrl + '?c=controle.CAppCaInfraLogin&m=validarCpf&nrCpf=' + cpf + '&idEmpresa=' + idEmpresa);
  }

  alterarSenhaLogado(cpf, senha) {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    return this.http.get(this.serverUrl + '?c=controle.CAppCaInfraLogin&m=alterarSenhaLogado&login=' + cpf + '&senha=' + senha);
  }

  login(cpf, senha, idEmpresa) {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    let ip = '0.0.0.0';
    let body = 'login=' + cpf + '&senha=' + senha + '&hdnNrIp=' + ip + '&idEmpresa=' + idEmpresa;
    return this.http.post(this.serverUrl + '?c=controle.CAppCaInfraLogin&m=validar', body, this.options);
  }

  gerarNovoCodigo(cpf, idEmpresa) {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    return this.http.get(this.serverUrl + '?c=controle.CAppCaInfraLogin&m=gerarNovoCodigo&nrCpf=' + cpf + '&idEmpresa=' + idEmpresa + '&conexao=' + this.conexao);
  }

  alterarSenha(cpf, senha, codigo) {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    return this.http.get(this.serverUrl + '?c=controle.CAppCaInfraLogin&m=alterarSenha&login=' + cpf + '&senha=' + senha + '&codigo=' + codigo + '&conexao=' + this.conexao);
  }

  confereVersao() {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    return this.http.get(this.serverUrl + '?c=controle.CAppCaInfraLogin&m=pegarVersaoMinima&conexao=' + this.conexao);
  }

  sair() {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    return this.http.get(this.serverUrl + '?c=controle.CAppCaInfraLogin&m=sair&conexao=' + this.conexao);
  }

  getDadosFuncionario(idFuncionario) {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    // return this.http.get(this.serverUrl + '?c=controle.CAppCrFuncionario&m=dadosFuncionario&idFuncionario=' + idFuncionario + '&debugOff=1');
    return this.http.get(this.serverUrl + '?c=controle.CAppCrFuncionario&m=dadosFuncionario&idFuncionario=' + idFuncionario); 
  }
}
