import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Network } from '@ionic-native/network/ngx';
import { AlertController, MenuController, ModalController, Platform } from '@ionic/angular';
import { TermosDeUsoModalPage } from './pages/termos-de-uso/termos-de-uso-modal/termos-de-uso-modal.page';
import { ToastService } from './services/components/toast/toast.service';
import { EnvironmentService } from './services/environment/environment.service';
import { GlobalFooService } from './services/global/global-foo.service';
import { RegistroDePontoService } from './services/registro-de-ponto/registro-de-ponto.service';
// import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { Subscription } from 'rxjs';
import { NotificacaoObrigatoriaDetalhesPage } from './pages/comunicacao/notificacao-obrigatoria-detalhes/notificacao-obrigatoria-detalhes/notificacao-obrigatoria-detalhes.page';
import { ComunicacaoService } from './services/comunicacao/comunicacao.service';
import { LoginService } from './services/login/login.service';
import { RegistrarLogService } from './services/registrar-log/registrar-log.service';
import { SocketService } from './services/socket/socket.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent {
  nmNome: string = '';
  nmEmpresa: string = '';
  menus: any;
  boLoginOffLineHabilitado: any = localStorage.getItem('boLoginOffLineHabilitado');
  habilitarBloqueio: any = localStorage.getItem('habilitarBloqueio');
  boErroSincronizacao: boolean;
  connectSubscription: any;
  disconnectSubscription: any;
  erro: any;
  bMode: boolean = false;
  counterBMode: number = 0;
  count: any = 0;
  countInterval: any;
  foto_funcionario: any;
  inter: Subscription;
  backgroundModeEnabled: boolean = false;

  constructor(
    public platform: Platform,
    private environmentService: EnvironmentService,
    private globalFooService: GlobalFooService,
    public menuCtrl: MenuController,
    private router: Router,
    public modalController: ModalController,
    private toast: ToastService,
    public registroDePontoService: RegistroDePontoService,
    public loginService: LoginService,
    private network: Network,
    // private backgroundMode: BackgroundMode,
    public faio: FingerprintAIO,
    private appVersion: AppVersion,
    public alertController: AlertController,
    private vibration: Vibration,
    private inAppBrowser: InAppBrowser,
    private registrarLog: RegistrarLogService,
    private comunicacaoService: ComunicacaoService,
    private socketService: SocketService,
  ) {
    localStorage.setItem('pauseIntervalBackground', 'false');
    localStorage.setItem('loadHome', 'false');
    localStorage.setItem('primeiroAcesso', 'true');
    this.environmentService.setClient('SRV32');
    this.getDados();
    this.confereVersao();
    if (this.boLoginOffLineHabilitado == "1" || this.boLoginOffLineHabilitado == 1) {
      this.checkConnection();
    }
    // this.termosDeUso();

    this.globalFooService.getObservable().subscribe((data) => {
      if (data.atualizar == true) {
        this.confereVersao();
        this.getDados();
        this.termosDeUso();
        this.verificaComunicacoesObrigatorias();
        if (this.habilitarBloqueio == 'true' || this.habilitarBloqueio == true) {
          // this.enableBackgroundMode();
        }

        // if (this.boLoginOffLineHabilitado == "1" || this.boLoginOffLineHabilitado == 1) {
        //   setTimeout(() => {
        //     this.sincronizarBatidasPonto();
        //     console.log(1)
        //   }, 3000);
        // }
      }
      // if (data.atualizar == true) {
        
      // }
    });
    this.globalFooService.getObservable().subscribe((data) => {
      if (data.abrirNotificacaoObrigatoria == true) {
        this.verificaComunicacoesObrigatorias();
      }
      if (data.atualizarConfigs == true) {
        this.habilitarBloqueio = localStorage.getItem('habilitarBloqueio');
        // if (this.habilitarBloqueio == 'true' || this.habilitarBloqueio == true) {
        // this.enableBackgroundMode(); 
        // } else {
        //   this.backgroundMode.disable();
        //   alert()
        // }
      }
    });
  }

  verificaComunicacoesObrigatorias() {
    this.comunicacaoService.getComunicacoesObrigatorias().subscribe((data) => {
      const response = (data as any);
      console.log('Comunicacao')
      // console.log(response)
      response.dados?.forEach(async (element: any) => {
        // console.log(element)
        const modal = await this.modalController.create({
          component: NotificacaoObrigatoriaDetalhesPage,
          backdropDismiss: false,
          componentProps: {
            dados: element
          }
        });
        modal.onDidDismiss().then((data) => {
          this.globalFooService.publishSomeData({ atualizarQtdNotificacoes: true });
        });
        return await modal.present();
      });
    })
  }

  confereVersao() {
    setTimeout(() => {
      this.appVersion.getVersionNumber().then(version => {

      localStorage.setItem('versaoApp', version);
        var version = version;
        var boVersaoDesatualizada = false;
        this.loginService.confereVersao().subscribe(data => {
          const response = (data as any);
          let versaoMinima = response.dados.valor

          let arVersaoMinima = versaoMinima.split(".");
          let arVersaoInstalada = version.split(".");

          if (parseInt(arVersaoInstalada[0]) < parseInt(arVersaoMinima[0])) {
            boVersaoDesatualizada = true;
          } else if (parseInt(arVersaoInstalada[0]) == parseInt(arVersaoMinima[0])) {
            if (parseInt(arVersaoInstalada[1]) < parseInt(arVersaoMinima[1])) {
              boVersaoDesatualizada = true;
            } else if (parseInt(arVersaoInstalada[1]) == parseInt(arVersaoMinima[1])) {
              if (parseInt(arVersaoInstalada[2]) < parseInt(arVersaoMinima[2])) {
                boVersaoDesatualizada = true;
              }
            }
          }

          if (boVersaoDesatualizada == true) {
            this.logout();
          }
        });
      }).catch(error => {
        console.error(error);
        localStorage.setItem('versaoApp', '2.1.26');
      });
    }, 2000);
  }
  
  // enableBackgroundMode() {
  //   console.log(this.habilitarBloqueio)
  //   this.platform.ready().then(() => {
  //     if ((this.habilitarBloqueio == 'true' || this.habilitarBloqueio == true) && this.backgroundModeEnabled != true) {
  //       this.backgroundModeEnabled = true;
  //       // this.backgroundMode.enable();
  //       this.platform.pause.subscribe(() => {
  //         // alert();
  //         var pauseIntervalBackground = localStorage.getItem('pauseIntervalBackground');

  //         this.inter = null;
  //         if (pauseIntervalBackground != 'true' && this.habilitarBloqueio == 'true') {
  //           this.inter = interval(1000).subscribe(async val => {
  //             console.log(val)
  //             if (val > 59 && this.habilitarBloqueio == 'true' && pauseIntervalBackground != 'true') {
  //               localStorage.setItem('pauseIntervalBackground', 'true');
  //               this.inter.unsubscribe();
  //               console.log(this.inter)
  //               const modal = await this.modalController.create({
  //                 component: BloqueioTelaPage,
  //                 backdropDismiss: false
  //               });
  //               modal.dismiss(data => {
  //                 localStorage.setItem('pauseIntervalBackground', 'false');
  //               })
  //               return await modal.present();
  //             }
  //           })
  //         }
  //         this.platform.resume.subscribe(() => {
  //           console.log(this.inter)
  //           this.inter.unsubscribe();
  //           console.log('ok')
  //         })

  //       })


  //     } else {
  //       // this.backgroundMode.disable();
  //       this.inter.unsubscribe();
  //     }

  //     // //   const timeoutId = setTimeout(() => {
  //     // //     this.faioPrompt();
  //     // // }, 10000);
  //     // // setTimeout(() => {
  //     // //   clearTimeout(timeoutId);
  //     // // }, 9000);
  //     // });
  //     // this.backgroundMode.on('deactivate').subscribe(() => {
  //     //   console.log('background 3')
  //     //   alert(2)
  //     //   this.backgroundMode.disable();
  //     //   const source = interval(1000);
  //     //   const subscribe = source.subscribe(val => {
  //     //   console.log(val)
  //     //     if (val > 10) { 
  //     //       this.faioPrompt(); 
  //     //       subscribe.unsubscribe();
  //     //     }
  //     //     setTimeout(() => {
  //     //       subscribe.unsubscribe();
  //     //     }, 11000);
  //     //   });
  //     // });
  //     // this.backgroundMode.on('activate').subscribe(() => {
  //     // });
  //   });
  // }

  faioPrompt() {
    // setTimeout(()=> {this.subscribe.unsubscribe()}, 2000);
    this.faio.isAvailable().then(result => {
      if (result === "finger" || result === "biometric" || result === "face") {
        this.faio.show({
          disableBackup: true,
          cancelButtonTitle: 'Cancelar',
          description: "Por favor, autentique-se. Para acessar usando senha, clique em cancelar.",
          title: 'Login',
          // fallbackButtonTitle: 'FB Back Button', 
          // subtitle: 'This SubTitle',
        }).then((result: any) => {
          console.log(result);
          if (result) {
            // this.backgroundMode.enable();
          } else {
            this.logout();
          }
        }).catch((error: any) => {
          console.error(error)
          this.logout();
        });
      } else {
        this.logout();
      }
    })
  }

  checkConnection() {
    this.platform.ready().then(() => {
      setTimeout(() => {
        this.disconnectSubscription = this.network.onDisconnect().subscribe(() => {
          this.toast.error('Atenção! Você está sem conexão com a internet, faça o Login Offline para registrar o ponto.');
          console.log('sem internet');
          this.logout();
        });
        this.connectSubscription = this.network.onConnect().subscribe(() => {
          this.toast.info('Conexão com a internet reestabelecida. Faça o login novamente!');
          console.log('com internet');
          this.logout();
        });
      }, 3000);
    });
  }

  logout() {
    this.menuCtrl.close().then(() => {
      this.loginService.sair().subscribe(data => {
        localStorage.removeItem('session_id');
        this.registrarLog.registrar(this.registrarLog.func.Sair).subscribe(data => { });
      });
      this.socketService.disconnect();
      this.router.navigateByUrl('/login', { replaceUrl: true });
    });
  }

  getDados() {
    if (localStorage.getItem('nmEmpresa') && localStorage.getItem('nmNome')) {
      this.nmNome = localStorage.getItem('nmNome');
      this.nmEmpresa = localStorage.getItem('nmEmpresa');
      const arrNome = this.nmNome.split(" ");
      this.nmNome = arrNome[0] + ' ' + arrNome[1];
      let urlFoto = localStorage.getItem('fotoFuncionario')
      if (urlFoto != "../.infra/visualizacao/css/novo/imagens/im_pessoasemfoto.png") {
        this.foto_funcionario = localStorage.getItem('SGTWEB_URL') + localStorage.getItem('fotoFuncionario');
      } else {
        this.foto_funcionario = null;
      }

      this.getMenus();
    }
  }

  getMenus() {
    setTimeout(() => {
      this.menus = JSON.parse(localStorage.getItem('menu'));
    }, 1000);
  }

  async termosDeUso() {
    let dtAceitacaoTermoRh = localStorage.getItem("dtAceitacaoTermoRh");
    let boTermoAtualizado = localStorage.getItem("boTermoAtualizado");
    let url = window.location.href;
    // alert(window.location.href)
    // if (!url.includes('login')) {
    if (!dtAceitacaoTermoRh || boTermoAtualizado == '1') {
      const modal = await this.modalController.create({
        component: TermosDeUsoModalPage,
        backdropDismiss: false
      });
      return await modal.present();
    }
    // }
  }

  sincronizarBatidasPonto() {
    console.log('Entrou na sincronizacao')
    // this.limpaBatidasStorage();

    const items = JSON.parse(localStorage.getItem('batidas'));
    if (items?.length) {
      console.log('entrou no loop da sincronizacao')
      this.toast.info('Sincronizando Registro de Batidas Offline com o servidor...');
      items.forEach((item, i) => {
        this.registroDePontoService.registrarBatidaPontoOffline(item.idFuncionario, item.lat, item.long, item.csTipoMarcacao, item.imgSelfie, item.hrBatida, item.dtBatida).subscribe(data => {
          const response = (data as any);
          if (response.sucesso == 1) {
            delete items[i];
            localStorage.setItem('batidas', JSON.stringify(items));
            localStorage.removeItem('ultimaBatida');
          } else {
            this.boErroSincronizacao = true;
          }

          if (i == items.length && this.boErroSincronizacao == true) {
            this.toast.error('Houveram inconsistências com algum dos pontos sincronizados, verifique com o Departamento Pessoal.');
          }
        }, error => {
          this.toast.error('Erro ao sincronizar Registro de Ponto Offline com o servidor.');
          console.log(error);
        })
        setTimeout(() => {
          this.limpaBatidasStorage();
        }, 5000);
      });
    }
  }

  limpaBatidasStorage() {
    const items = JSON.parse(localStorage.getItem('batidas'));
    if (items) {
      items.forEach((item, i) => {
        console.log(item)
        if (item == null) {
          items.splice(i, 1);
          localStorage.setItem('batidas', JSON.stringify(items));
        }
      });
    }
  }

}
