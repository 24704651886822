import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { LoadingService } from 'src/app/services/components/loading/loading.service';
import { ComunicacaoService } from 'src/app/services/comunicacao/comunicacao.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@Component({
  selector: 'app-notificacao-obrigatoria-detalhes',
  templateUrl: './notificacao-obrigatoria-detalhes.page.html',
  styleUrls: ['./notificacao-obrigatoria-detalhes.page.scss'],
})
export class NotificacaoObrigatoriaDetalhesPage implements OnInit {
  logoClient: string = localStorage.getItem('LOGO');
  dados: any = this.navParams.get('dados');
  sgtwebUrl: any = localStorage.getItem('SGTWEB_URL');

  constructor(
    private modalController: ModalController, 
    private navParams: NavParams,
    private comunicacaoService: ComunicacaoService,
    public loading: LoadingService,
    public platform: Platform,
    private inAppBrowser: InAppBrowser,
    ) { }

  ngOnInit() {
    console.log(this.dados)
  }

  async verAnexo(anexo, tipo) {
    let url = this.sgtwebUrl + anexo;
    this.inAppBrowser.create(url, '_system', 'location=no');
    
  }

  fecharModal() {
    this.comunicacaoService.setDtVisualizacaoOcorrencia(this.dados.idOcorrencia).subscribe(data => {
      this.modalController.dismiss();
    });
  }

}
