import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  client: any;
  environment: string;
 
  constructor() {
    // this.setClient('piracicabana');
  }

  setClient(client) {  
    client = 'gestao';   
    this.setArrayClients();
    localStorage.setItem('CLIENTE', this.client[client].CLIENTE);
    localStorage.setItem('SERVER_URL', this.client[client].SERVER_URL);
    localStorage.setItem('SGTWEB_URL', this.client[client].SGTWEB_URL);
    // localStorage.setItem('SOCKET', this.client[client].SOCKET);
    localStorage.setItem('CONEXAO', this.client[client].CONEXAO);
    localStorage.setItem('LOGO', this.client[client].LOGO);
    localStorage.setItem('LOGO_LOGIN', this.client[client].LOGO_LOGIN); 
    localStorage.setItem('APP', this.client[client].APP);
    localStorage.setItem('URL_PLAYSTORE', this.client[client].URL_PLAYSTORE);
    localStorage.setItem('URL_APPLE', this.client[client].URL_APPLE);
    localStorage.setItem('ID_EMPRESAS', this.client[client].ID_EMPRESA);
  }

  private setArrayClients() {  
    this.client = {
      'SRV32': {
        CLIENTE: "RIOITA_SRV32",
        SERVER_URL: "https://3ec9075a51a06a5ec0adf1478709e3c6e7fb421f.transoft.com.br/eliasc/sgtweb/transapp.php",
        SGTWEB_URL: "https://3ec9075a51a06a5ec0adf1478709e3c6e7fb421f.transoft.com.br/eliasc/sgtweb/",
        SOCKET: "ws://3ec9075a51a06a5ec0adf1478709e3c6e7fb421f.transoft.com.br:7182",
        CONEXAO: "rioita",
        LOGO: "./assets/logos/srv32/transoft-branca.png",
        LOGO_LOGIN: "./assets/logos/srv32/logo-ts.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.transoft.mobile_rh",
        URL_APPLE: "itms-services://?action=download-manifest&url=https://www.transoft.com.br/app/manifest.plist",
        ID_EMPRESA: Array('001', '200', '004')
      },
      'piracicabana': {
        CLIENTE: "PIRACICABANA",
        SERVER_URL: "https://piracicabana.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://piracicabana.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7185",
        CONEXAO: "piracicabana",
        LOGO: "./assets/logos/piracicabana/piracicabana-branca.png",
        LOGO_LOGIN: "./assets/logos/piracicabana/logo-piracicabana.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.piracicabana.mobile_rh",
        URL_APPLE: "https://apps.apple.com/us/app/piracicabana-mobile-rh/id1662655861",
        ID_EMPRESA: ''
      },
      'piracicabana_homolog': {
        CLIENTE: "PIRACICABANA",
        SERVER_URL: "http://homolog.piracicabana.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "http://homolog.piracicabana.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7180",
        CONEXAO: "homolog_piracicabana",
        LOGO: "./assets/logos/dho/dho-branca.png",
        LOGO_LOGIN: "./assets/logos/dho/logo-dho.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.piracicabana.mobile_rh",
        URL_APPLE: "https://apps.apple.com/us/app/piracicabana-mobile-rh/id1662655861",
        ID_EMPRESA: ''
      },
      'veracruz': {
        CLIENTE: "VERA_CRUZ",
        SERVER_URL: "https://veracruz.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://veracruz.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7186",
        CONEXAO: "transnet",
        LOGO: "./assets/logos/veracruz/vera-branca.png",
        LOGO_LOGIN: "./assets/logos/veracruz/logo-veracruz.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.veracruz.mobile_rh",
        URL_APPLE: "https://apps.apple.com/us/app/vera-cruz-online-rh/id1666369169",
        ID_EMPRESA: Array('001')
      },
      'nspenha': {
        CLIENTE: "NS_PENHA",
        SERVER_URL: "https://nspenha.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://nspenha.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7196",
        CONEXAO: "transnet",
        LOGO: "./assets/logos/nspenha/nspenha-branca.png",
        LOGO_LOGIN: "./assets/logos/nspenha/logo-nspenha.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.nspenha.mobile_rh",
        URL_APPLE: "https://apps.apple.com/us/app/ns-penha-mobile-rh/id6445826635",
        ID_EMPRESA: Array('188')
      },
      'gestao': {
        CLIENTE: "TRANSOFT",
        SERVER_URL: "https://tecno.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://tecno.transoft.com.br/sgtweb/",
        SOCKET: "wss://socket.transoft.com.br:7181",
        CONEXAO: "tecno",
        LOGO: "./assets/logos/srv32/transoft-branca.png",
        LOGO_LOGIN: "./assets/logos/srv32/logo-ts.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.transoft.mobile_rh",
        URL_APPLE: "https://apps.apple.com/us/app/transoft-mobile-rh/id1420219665",
        ID_EMPRESA: ''
      },
      'pontecoberta': {
        CLIENTE: "PONTE_COBERTA",
        SERVER_URL: "https://grupopontecoberta.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://grupopontecoberta.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7187",
        CONEXAO: "grupopontecoberta",
        LOGO: "./assets/logos/pontecoberta/pontecoberta-branca.png",
        LOGO_LOGIN: "./assets/logos/pontecoberta/logo-pontecoberta.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.pontecoberta.mobilerh",
        URL_APPLE: "https://apps.apple.com/br/app/ponte-coberta-mobile-rh/id6447009456",
        // ID_EMPRESA: '001'
        ID_EMPRESA: ''
      },
      'pontecoberta_homolog': {
        CLIENTE: "PONTE_COBERTA",
        SERVER_URL: "http://homolog.grupopontecoberta.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "http://homolog.grupopontecoberta.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7180",
        CONEXAO: "grupopontecobertates",
        LOGO: "./assets/logos/pontecoberta/pontecoberta-branca.png",
        LOGO_LOGIN: "./assets/logos/pontecoberta/logo-pontecoberta.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.pontecoberta.mobilerh",
        URL_APPLE: "https://apps.apple.com/us/app/ponte-coberta-mobile-rh/id6447009456",
        // ID_EMPRESA: '001'
        ID_EMPRESA: ''
      },
      'gloria': {
        CLIENTE: "GLORIA",
        SERVER_URL: "https://grupopontecoberta.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://grupopontecoberta.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7187",
        CONEXAO: "grupopontecoberta",
        LOGO: "./assets/logos/gloria/gloria-branca.png",
        LOGO_LOGIN: "./assets/logos/gloria/logo-gloria.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.gloria.transoft.mobilerh",
        URL_APPLE: "https://apps.apple.com/us/app/glória-mobile-rh/id6447009396",
        // ID_EMPRESA: '002'
        ID_EMPRESA: ''
      },
      'gardel': {
        CLIENTE: "GARDEL",
        SERVER_URL: "https://grupopontecoberta.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://grupopontecoberta.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7187",
        CONEXAO: "grupopontecoberta",
        LOGO: "./assets/logos/gardel/gardel-branca.png",
        LOGO_LOGIN: "./assets/logos/gardel/logo-gardel.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.gardel.mobile_rh",
        URL_APPLE: "https://apps.apple.com/us/app/gardel-mobile-rh/id6447009153",
        // ID_EMPRESA: '003'
        ID_EMPRESA: ''
      },
      'itajai': {
        CLIENTE: "ITAJAI",
        SERVER_URL: "https://itajai.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://itajai.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7192",
        CONEXAO: "transnet",
        LOGO: "./assets/logos/itajai/itajai-branca.png",
        LOGO_LOGIN: "./assets/logos/itajai/logo-itajai.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.transoft.itajai",
        URL_APPLE: "https://apps.apple.com/us/app/itajaí-mobile-rh/id1671626877",
        ID_EMPRESA: Array('001')
      },
      'onicamp': {
        CLIENTE: "ONICAMP",
        SERVER_URL: "https://itajai.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://itajai.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7192",
        CONEXAO: "transnet",
        LOGO: "./assets/logos/onicamp/onicamp-branca.png",
        LOGO_LOGIN: "./assets/logos/onicamp/logo-onicamp.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.transoft.onicamp",
        URL_APPLE: "https://apps.apple.com/us/app/onicamp-mobile-rh/id1671626923",
        ID_EMPRESA: Array('002')
      },
      'fenix': {
        CLIENTE: "FENIX",
        SERVER_URL: "https://grupofenix.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://grupofenix.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7183",
        CONEXAO: "grupofenix",
        LOGO: "./assets/logos/fenix/fenix-branca.png",
        LOGO_LOGIN: "./assets/logos/fenix/logo-fenix.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.fenix.mobile_rh",
        URL_APPLE: "https://apps.apple.com/us/app/fenix-mobile-rh/id1665281684",
        // ID_EMPRESA: Array('002', '004') 
        ID_EMPRESA: ''
      },
      'fenix_homolog': {
        CLIENTE: "FENIX",
        SERVER_URL: "http://homolog.grupofenix.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "http://homolog.grupofenix.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7180",
        CONEXAO: "grpfente",
        LOGO: "./assets/logos/srv32/transoft-branca.png",
        LOGO_LOGIN: "./assets/logos/srv32/logo-ts.png",
        // LOGO: "./assets/logos/fenix/fenix-branca.png",
        // LOGO_LOGIN: "./assets/logos/fenix/logo-fenix.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.fenix.mobile_rh",
        URL_APPLE: "https://apps.apple.com/us/app/fenix-mobile-rh/id1665281684",
        // ID_EMPRESA: Array('002', '004', '005')
        ID_EMPRESA: ''
      },
      'dho': { 
        CLIENTE: "RIOITA",
        SERVER_URL: "https://rioita.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://rioita.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7190",
        CONEXAO: "rioita",
        LOGO: "./assets/logos/dho/dho-branca.png",
        LOGO_LOGIN: "./assets/logos/dho/logo-dho.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.transoft.dhomobilidade",
        URL_APPLE: "https://apps.apple.com/us/app/dho-mobile-rh/id1666366864",
        ID_EMPRESA: ''
      },
      'dho_homolog': { 
        CLIENTE: "RIOITA",
        SERVER_URL: "https://rioita.transoft.com.br:8081/homolog/sgtweb/transapp.php",
        SGTWEB_URL: "https://rioita.transoft.com.br:8081/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7180",
        CONEXAO: "teste11",
        LOGO: "./assets/logos/dho/dho-branca.png",
        LOGO_LOGIN: "./assets/logos/dho/logo-dho.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.transoft.dhomobilidade",
        URL_APPLE: "https://apps.apple.com/us/app/dho-mobile-rh/id1666366864",
        ID_EMPRESA: ''
      },
      'jal': { 
        CLIENTE: "JAL",
        SERVER_URL: "https://30fe48da2510d82c2fc13843e2c6256770beae2a.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://30fe48da2510d82c2fc13843e2c6256770beae2a.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7182",
        CONEXAO: "grupojal",
        LOGO: "./assets/logos/jal/jal-branca.png",
        LOGO_LOGIN: "./assets/logos/jal/logo-jal.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.transoft.grupojal",
        URL_APPLE: "https://apps.apple.com/us/app/jal-mobile-rh/id6445826936",
        ID_EMPRESA: ''
      },
      'riodouro': { 
        CLIENTE: "RIODOURO",
        SERVER_URL: "https://30fe48da2510d82c2fc13843e2c6256770beae2a.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://30fe48da2510d82c2fc13843e2c6256770beae2a.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7195",
        CONEXAO: "grupojal",
        LOGO: "./assets/logos/riodouro/riodouro-branca.png",
        LOGO_LOGIN: "./assets/logos/riodouro/logo-riodouro.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.transoft.riodouro",
        URL_APPLE: "https://apps.apple.com/us/app/rio-douro-mobile-rh/id6449135025",
        ID_EMPRESA: '007'
      },
      'pendotiba': { 
        CLIENTE: "PENDOTIBA",
        SERVER_URL: "https://transnetap.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://transnetap.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7191",
        CONEXAO: "pendotiba",
        LOGO: "./assets/logos/pendotiba/pendotiba-branca.png",
        LOGO_LOGIN: "./assets/logos/pendotiba/logo-pendotiba.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.pendotiba.mobile_rh",
        URL_APPLE: "https://apps.apple.com/us/app/pendotiba-mobile-rh/id1672399873",
        ID_EMPRESA: Array('001')
      },
      'aracatuba': { 
        CLIENTE: "ARACATUBA",
        SERVER_URL: "https://transnetap.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://transnetap.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7197",
        CONEXAO: "pendotiba",
        LOGO: "./assets/logos/aracatuba/aracatuba-branca.png",
        LOGO_LOGIN: "./assets/logos/aracatuba/logo-aracatuba.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.transoft.aracatuba",
        URL_APPLE: "https://apps.apple.com/us/app/araçatuba-mobile-rh/id1666999756",
        ID_EMPRESA: Array('004')
      },
      'saogoncalo': { 
        CLIENTE: "SAO_GONCALO",
        SERVER_URL: "https://saogoncalo.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://saogoncalo.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7193",
        CONEXAO: "saogoncalo",
        LOGO: "./assets/logos/saogoncalo/saogoncalo-branca.png",
        LOGO_LOGIN: "./assets/logos/saogoncalo/logo-saogoncalo.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.saogoncalo.mobile_rh",
        URL_APPLE: "https://apps.apple.com/us/app/são-gonçalo-mobile-rh/id1666999280",
        ID_EMPRESA: ''
      },
      'gem': { 
        CLIENTE: "GEM",
        SERVER_URL: "https://grupogem.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://grupogem.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7189",
        CONEXAO: "grupogem",
        LOGO: "./assets/logos/gem/gem-branca.png",
        LOGO_LOGIN: "./assets/logos/gem/logo-gem.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.transoft.gem",
        URL_APPLE: "https://apps.apple.com/us/app/gem-mobile-rh/id1667000161",
        ID_EMPRESA: ''
      },
      'atlantico': { 
        CLIENTE: "ATLANTICO",
        SERVER_URL: "https://atlantico.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://atlantico.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7184",
        CONEXAO: "atlantico",
        LOGO: "./assets/logos/atlantico/atlantico-branca.png",
        LOGO_LOGIN: "./assets/logos/atlantico/logo-atlantico.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.atlantico.mobilerh",
        URL_APPLE: "https://apps.apple.com/us/app/atlântico-mobile-rh/id6448806044",
        ID_EMPRESA: ''
      },
      'csc': { 
        CLIENTE: "CSC",
        SERVER_URL: "https://transnet.grupocsc.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://transnet.grupocsc.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7188",
        CONEXAO: "grupocsc",
        LOGO: "./assets/logos/csc/csc-branca.png",
        LOGO_LOGIN: "./assets/logos/csc/logo-csc.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.csc.mobilerh",
        URL_APPLE: "https://apps.apple.com/us/app/grupo-csc-mobile-rh/id6462489759",
        ID_EMPRESA: '' 
      },
      'semfronteiras': { 
        CLIENTE: "SEM_FRONTEIRAS",
        SERVER_URL: "https://semfronteiras.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://semfronteiras.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7194",
        CONEXAO: "semfronteiras",
        LOGO: "./assets/logos/sem-fronteiras/sem-fronteiras-branca.png",
        LOGO_LOGIN: "./assets/logos/sem-fronteiras/logo-sem-fronteiras.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.semfronteiras.mobilerh",
        URL_APPLE: "https://apps.apple.com/us/app/sem-fronteiras-mobile-rh/id6471435149",
        ID_EMPRESA: ''
      },
      'otima': { 
        CLIENTE: "CONSORCIO_OTIMA",
        SERVER_URL: "https://otima.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://otima.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7195",
        CONEXAO: "otima",
        LOGO: "./assets/logos/otima/otima-branca.png",
        LOGO_LOGIN: "./assets/logos/otima/logo-otima.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.otima.mobilerh",
        URL_APPLE: "https://apps.apple.com/us/app/otima-mobile-rh/id6474261165",
        ID_EMPRESA: ''
      },
      'viafacil': { 
        CLIENTE: "TAGUATUR",
        SERVER_URL: "https://taguatur.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://taguatur.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7196",
        CONEXAO: "taguatur",
        LOGO: "./assets/logos/viafacil/viafacil-branca.png",
        LOGO_LOGIN: "./assets/logos/viafacil/logo-viafacil.png",
        APP: "transnet",
        URL_PLAYSTORE: "https://play.google.com/store/apps/details?id=com.transnet.viafacil.mobilerh",
        URL_APPLE: "https://apps.apple.com/us/app/via-facil-mobile-rh/id6477399257",
        ID_EMPRESA: Array('104','105')
      },
      'mobi': { 
        CLIENTE: "MOBI",
        SERVER_URL: "https://mobi.transoft.com.br/sgtweb/transapp.php",
        SGTWEB_URL: "https://mobi.transoft.com.br/sgtweb/",
        SOCKET: "ws://socket.transoft.com.br:7197",
        CONEXAO: "Mobi",
        LOGO: "./assets/logos/mobi/mobi-branca.png",
        LOGO_LOGIN: "./assets/logos/mobi/logo-mobi.png",
        APP: "transnet",
        URL_PLAYSTORE: "",
        URL_APPLE: "",
        ID_EMPRESA: ''
      },
    }
  }
}