import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { LocalNotifications } from "@awesome-cordova-plugins/local-notifications/ngx";
import { Vibration } from "@awesome-cordova-plugins/vibration/ngx";
import { webSocket } from 'rxjs/webSocket';
import { ToastService } from "../components/toast/toast.service";
import { GlobalFooService } from "../global/global-foo.service";

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  // private socket$ = webSocket('ws://168.138.153.82:7180');
  socketAccess = localStorage.getItem('SOCKET');
  socket$;
  clickSub: any;
  // private socket$ = webSocket(this.socketAccess);
  backgroundStatus: boolean = false;

  constructor(
    private globalFooService: GlobalFooService,
    private toast: ToastService,
    private localNotifications: LocalNotifications,
    private vibration: Vibration,
    private router: Router,
    private zone: NgZone

  ) {
    setTimeout(() => {
      this.socketAccess = localStorage.getItem('SOCKET');
      console.log("Porta do socket", this.socketAccess)
      this.socket$ = webSocket(this.socketAccess);
    }, 5000);
    if (typeof document.hidden !== 'undefined') {
      document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
    }
  }

  handleVisibilityChange() {
    if (document.hidden) {
      console.log('Página em segundo plano')
      this.backgroundStatus = true;
    } else {
      console.log('Página em primeiro plano')
      this.backgroundStatus = false;
    }
  }

  connect() {
    console.log('SOCKET 1')
    return this.socket$.subscribe((message) => {
        const msg = (message as any);
        // alert(2)
        console.log('SOCKET 2')
        console.log("conectado ao socket")
        // alert()
        if (msg.mensagem) {
          console.log('chegou:' + JSON.stringify(msg))
          if(msg.bo_ocorrencia_obrigatoria == 1) {
            this.globalFooService.publishSomeData({ abrirNotificacaoObrigatoria: true });  
          }
          this.globalFooService.publishSomeData({ push: message });
          this.clickSub = this.localNotifications.on('click').subscribe(data => {
            this.zone.run(() => {
              this.router.navigateByUrl('/comunicacao/listar', { replaceUrl: true });
            });
            this.clickSub.unsubscribe();
          });
          this.vibration.vibrate(200);
          if (this.backgroundStatus == true) {
            this.localNotifications.schedule({
              id: msg.idOcorrencia,
              // id: Math.floor(Math.random() * 10),
              text: msg.mensagem,
              foreground: true,
              launch: true,
            });
          }
          this.toast.info(msg.mensagem, 60000)
        }
      },
      (err) => {
        // alert(3)
        console.log(3)
        console.error('WebSocket error:', err);
      },
      () => {
        // alert(4)
        console.log(4)
        console.log('WebSocket connection closed');
      }
    );
  }

  disconnect() {
    return this.socket$.complete();
  }

  sendMessage(message: any) {
    this.socket$.next(message);
  }

  public getSafehtml(html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html
    return txt.value;
  }


}