import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TermosDeUsoService {
  serverUrl: string = localStorage.getItem('SERVER_URL');

  constructor(private http: HttpClient) { }

  getTermosDeUso() {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    return this.http.get(this.serverUrl + '?c=controle.CAppCrFuncionario&m=lerTermoUso');
  }

  aceitarTermoUso() {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    return this.http.get(this.serverUrl + '?c=controle.CAppCrFuncionario&m=aceitarTermoUso');
  }
}
