import { RegistrarLogService } from './../../../services/registrar-log/registrar-log.service';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { LoadingService } from 'src/app/services/components/loading/loading.service';
import { TermosDeUsoService } from 'src/app/services/termos-de-uso/termos-de-uso.service';

@Component({
  selector: 'app-termos-de-uso-modal',
  templateUrl: './termos-de-uso-modal.page.html',
  styleUrls: ['./termos-de-uso-modal.page.scss'],
})
export class TermosDeUsoModalPage implements OnInit {
  logoClient: string = localStorage.getItem('LOGO');
  termo: any;

  constructor(
    private termosDeUsoService: TermosDeUsoService,
    private loading: LoadingService,
    private sanitizer: DomSanitizer,
    public menuCtrl: MenuController,
    private router: Router,
    public modalController: ModalController,
    private registrarLog: RegistrarLogService
  ) { 
    this.registrarLog.registrar(this.registrarLog.func.Termos).subscribe(data => { });
  }

  getTermo() {
    this.loading.iniciar();
    this.termosDeUsoService.getTermosDeUso().subscribe(data => {
      const response = (data as any);
      this.termo = this.sanitizer.bypassSecurityTrustHtml(response.dados.termo);
      this.loading.fim();
    });
  }
  dissmiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  discordar() {
    this.menuCtrl.close().then(() => {
      this.dissmiss();
      this.router.navigateByUrl('/login', { replaceUrl: true });
    });
  }
  concordar() {
    this.termosDeUsoService.aceitarTermoUso().subscribe(data => {
      this.registrarLog.registrar(this.registrarLog.func.AceitarTermoUso).subscribe(data => { });
      const date = new Date();
      let dataAtual = date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
      localStorage.setItem("dtAceitacaoTermoRh", dataAtual);
      localStorage.setItem('boTernoAtualizado', '0')
      this.dissmiss();
    });
  }

  ngOnInit() {
    this.getTermo();
  }

}
