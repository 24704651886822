import { Platform } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer';
// import { File } from '@awesome-cordova-plugins/file';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';



@Injectable({
  providedIn: 'root'
})
export class ComunicacaoService {
  serverUrl: string = localStorage.getItem('SERVER_URL');
  idFuncionario: any = localStorage.getItem('idFuncionario');
  session_id: any = localStorage.getItem('session_id');
  options: { headers: HttpHeaders; };

  constructor(
    private http: HttpClient,
    private platform: Platform,
    // private transfer: FileTransfer,
    private http2: HTTP
  ) {
    this.platform.ready().then(() => {
      this.serverUrl = localStorage.getItem('SERVER_URL');
    });
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    this.options = {
      headers: headers
    }
  }

  getComunicacoesObrigatorias() {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    return this.http.get(this.serverUrl + '?c=controle.CAppCrOcorrenciaFuncionario&m=listarObrigatorias');
  }

  getOcorrencias(status, csTipo = '0', stCompetencia = '', ignoraOcorrenciaAssinatura = '0') {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    let body = 'status=' + status + '&csTipo=' + csTipo + '&stCompetencia=' + stCompetencia + '&ignoraOcorrenciaAssinatura=' + ignoraOcorrenciaAssinatura;
    return this.http.post(this.serverUrl + '?c=controle.CAppCrOcorrenciaFuncionario&m=listar', body, this.options);
  }

  getMotivosOcorrencias(csTipo) {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    return this.http.get(this.serverUrl + '?c=controle.CAppCrOcorrenciaFuncionario&m=listarMotivos&csTipo=' + csTipo);
  }

  setDtVisualizacaoOcorrencia(idOcorrencia) {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    return this.http.get(this.serverUrl + '?c=controle.CAppCrOcorrenciaFuncionario&m=alterarDataVisualizacaoResposta&idOcorrencia=' + idOcorrencia);
  }

  getQtdMensagensNaoLidas() {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    return this.http.get(this.serverUrl + '?c=controle.CAppCrOcorrenciaFuncionario&m=contagemOcoPorFuncionario');
  }

  getValidarUsuarioLogado() {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    return this.http.get(this.serverUrl + '?c=controle.CAppCaInfraLogin&m=validarUsuarioLogado');
  }

  registrarOcorrencia(stCompetencia, ocorrencia, motivo, dtReferenciaPonto, dtReferencia, img) {
    this.serverUrl = localStorage.getItem('SERVER_URL');
    var myData = 'img=' + img;
    stCompetencia = stCompetencia && stCompetencia != 'undefined' ? stCompetencia : '';

    if (dtReferenciaPonto) {
      var body = 'stCompetencia=' + stCompetencia + '&teOcorrencia=' + ocorrencia + '&idMotivo=' + motivo + '&dtReferenciaPonto=' + dtReferenciaPonto + '&csOrigem=1&' + myData
    } else {
      var body = 'stCompetencia=' + stCompetencia + '&teOcorrencia=' + ocorrencia + '&idMotivo=' + motivo + '&dtReferencia=' + dtReferencia + '&csOrigem=1&' + myData
    }
    return this.http.post(this.serverUrl + '?c=controle.CAppCrOcorrenciaFuncionario&m=inserir', body, this.options);
  }

  // uploadVideo(formData) {
  //   return this.http.post<any>(this.serverUrl  + '?c=controle.CAppCrOcorrenciaFuncionario&m=videoUpload', formData);
  // }

  uploadVideo(fullPathVideo, nameVideo, typeVideo, stCaminho, session_id = this.session_id) {
    return this.http2.uploadFile(this.serverUrl + '?c=controle.CAppCrOcorrenciaFuncionario&m=videoUpload', {
      fileName: nameVideo,
      nameVideo: nameVideo,
      mimeType: typeVideo,
      stCaminho: stCaminho,
      session: session_id
    }, {}, encodeURI(fullPathVideo), 'videoOcorrencia');
  }



  // uploadVideo(fullPathVideo, nameVideo, typeVideo, stCaminho) {
  //   this.platform.ready().then(() => {
  //     const fileTransfer: FileTransferObject = this.transfer.create();

  //       // let options: FileUploadOptions = {
  //       //    fileKey: 'file',
  //       //    fileName: 'name.jpg',
  //       //    headers: {}
  //       // }

  //     let options: FileUploadOptions = {
  //       fileKey: 'videoOcorrencia',
  //       fileName: nameVideo,
  //       chunkedMode: false,
  //       mimeType: typeVideo,
  //       params: { stCaminho: stCaminho, nameVideo: nameVideo },
  //       headers: {}
  //     }

  //     fileTransfer.upload(fullPathVideo, this.serverUrl + '?c=controle.CAppCrOcorrenciaFuncionario&m=videoUpload', options)
  //       .then((data) => {
  //         console.log(JSON.stringify(data) + " Uploaded Successfully");

  //       }, (err) => {
  //         console.log(err);
  //       });
  //   });
  // }

}
