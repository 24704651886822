import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loading: any;

  constructor(
    private loadingController: LoadingController
  ) { }

  async iniciar(duration = 7000) {
      
      this.loading = await this.loadingController.create({
        message: 'Carregando...',
        duration: duration,
        cssClass: 'loader-css-class',
      }).then((response) => {
        response.present();
      });
  }

  async fim() {
      await this.loadingController.getTop().then(async loader => {
        if (await loader) {
          await loader.dismiss();
        } else {
          setTimeout(async () => {
            await this.loadingController.getTop().then(async loader => {
                await loader.dismiss();
            });
          }, 3000);
        }
      });
}
  
  // async fim() {
  //   await this.loading.dismiss().then((res) => {
  //   }).catch((error) => {
  //     console.error('error', error);
  //   });
  // }
}
